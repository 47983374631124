import { sprintf } from "sprintf-js";
const Utils = {};

/**
 * Asserts that a premise is true.
 */
Utils.assertTrue = function(premise, message) {
  if (!premise) {
    throw new Error(message);
  }
};

/**
 * Asserts that a value is not null or undefined.
 */
Utils.assertNotNull = function(value, name) {
  Utils.assertTrue(
    value !== null && typeof value !== undefined,
    sprintf("%s must be provided", name || "A value")
  );
  return value;
};

Utils.isString = function(value) {
  return typeof value === "string";
};

Utils.assertIsNonEmptyString = function(value, key) {
  if (!value || typeof value !== "string") {
    throw new Error(key + " is not a non-empty string!");
  }
};

Utils.assertIsList = function(value, key) {
  if (!Array.isArray(value)) {
    throw new Error(key + " is not an array");
  }
};

Utils.assertIsEnum = function(value, allowedValues, key) {
  var i;
  for (i = 0; i < allowedValues.length; i++) {
    if (allowedValues[i] === value) {
      return;
    }
  }
  throw new Error(
    key + " passed is not valid. " + "Allowed values are: " + allowedValues
  );
};

/**
 * Generate an enum from the given list of lower-case enum values,
 * where the enum keys will be upper case.
 *
 * Conversion from pascal case based on code from here:
 * http://stackoverflow.com/questions/30521224
 */
Utils.makeEnum = function(values) {
  var enumObj = {};

  values.forEach(function(value) {
    var key = value
      .replace(/\.?([a-z]+)_?/g, function(x, y) {
        return y.toUpperCase() + "_";
      })
      .replace(/_$/, "");

    enumObj[key] = value;
  });

  return enumObj;
};

/**
 * Determine if the given value is a callable function type.
 * Borrowed from Underscore.js.
 */
Utils.isFunction = function(obj) {
  return !!(obj && obj.constructor && obj.call && obj.apply);
};

Utils.isObject = function(value) {
  return !(typeof value !== "object" || value === null);
};

Utils.isString = function(value) {
  return typeof value === "string";
};

Utils.isNumber = function(value) {
  return typeof value === "number";
};

const wsRegex = new RegExp("^(wss://)\\w*");
Utils.validWSUrl = function (wsUrl) {
  return wsRegex.test(wsUrl);
};

Utils.assertIsObject = function(value, key) {
  if (!Utils.isObject(value)) {
    throw new Error(key + " is not an object!");
  }
};

export default Utils;

