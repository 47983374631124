import Utils from "./utils";
import { LogManager, LogLevel, Logger } from "./log";
import {
    MIN_WEBSOCKET_LIFETIME,
    MAX_LINEAR_CONNECT_ATTEMPTS,
    MAX_EXPONENTIAL_CONNECT_ATTEMPTS,
    HEARTBEAT_INTERVAL,
    ROUTE_KEY
} from "./constants";


const WebSocketManager = function() {

    const logger = LogManager.getLogger({});

    let webSocket = null;

    let reconnectConfig = {
        reconnectWebSocket: false,
        websocketInitFailed: false,
        linearConnectAttempt: 0,
        exponentialConnectAttempt: 0,
        exponentialBackOffTime: 1,
        exponentialTimeoutHandle: null,
        lifeTimeTimeoutHandle: null
    };

    let heartbeatConfig = {
        pendingResponse: false,
        intervalHandle: null
    };

    let callbacks = {
        initFailure: new Set(),
        getWebSocketTransport: null,
        subscriptionUpdate: new Set(),
        subscriptionFailure: new Set(),
        topic: new Map(),
        allMessage: new Set(),
        connectionGain: new Set(),
        connectionLost: new Set()
    };

    let webSocketConfig = {
        connConfig: null,
        promiseHandle: null,
        promiseCompleted: false
    };

    let topicSubscription = {
        subscribed: new Set(),
        pending: new Set()
    };

    const invalidSendMessageRouteKeys = new Set([ROUTE_KEY.SUBSCRIBE, ROUTE_KEY.UNSUBSCRIBE, ROUTE_KEY.HEARTBEAT]);

    let online = navigator.onLine;
    const networkConnectivityChecker = setInterval(function () {
        if (online !== navigator.onLine) {
            online = navigator.onLine;
            if (online && (!webSocket || webSocket.readyState > 1)) {
                logger.info("Network online, Connecting to websocket");
                getWebSocketConnConfig();
            }
        }
    }, 250);

    const invokeCallbacks = function(callbacks, response) {
        callbacks.forEach(function (callback) {
            callback(response);
        });
    };

    const sendHeartBeat = function() {
        if (heartbeatConfig.pendingResponse) {
            logger.warn("Heartbeat response not received");
            clearInterval(heartbeatConfig.intervalHandle);
            heartbeatConfig.pendingResponse = false;
            refreshWebSocketConnection();
            return;
        }
        logger.debug("Sending heartbeat");
        webSocket.send(createWebSocketPayload(ROUTE_KEY.HEARTBEAT));
        heartbeatConfig.pendingResponse = true;
    };

    const resetState = function() {
        reconnectConfig.linearConnectAttempt = 0;
        reconnectConfig.exponentialConnectAttempt = 0;
        reconnectConfig.exponentialBackOffTime = 1;
        heartbeatConfig.pendingResponse = false;
        reconnectConfig.reconnectWebSocket = false;

        clearTimeout(reconnectConfig.lifeTimeTimeoutHandle);
        clearInterval(heartbeatConfig.intervalHandle);
        clearTimeout(reconnectConfig.exponentialTimeoutHandle);
    };

    const webSocketOnOpen = function() {
        try {
            logger.info("WebSocket connection established!");
            invokeCallbacks(callbacks.connectionGain);

            resetState();

            if (topicSubscription.subscribed.size > 0 || topicSubscription.pending.size > 0) {
                let topics = Array.from(topicSubscription.subscribed.values());
                topics = topics.concat(Array.from(topicSubscription.pending.values()));
                topicSubscription.subscribed.clear();
                webSocket.send(createWebSocketPayload(ROUTE_KEY.SUBSCRIBE, {"topics": topics}));
            }

            sendHeartBeat();
            heartbeatConfig.intervalHandle = setInterval(sendHeartBeat, 1000 * HEARTBEAT_INTERVAL);

            reconnectConfig.lifeTimeTimeoutHandle = setTimeout(function() {
                logger.debug("Starting scheduled WebSocket manager reconnect");
                refreshWebSocketConnection();
            }, 1000 * webSocketConfig.connConfig.webSocketTransport.transportLifeTimeInSeconds);
        } catch (error) {
            logger.error("Error after establishing web socket connection, error: ", error);
        }
    };

    const webSocketOnClose = function(event) {
        if (reconnectConfig.linearConnectAttempt <= 1) {
            invokeCallbacks(callbacks.connectionLost);
        }
        logger.info("Socket connection is closed. event: ", event);
        if (reconnectConfig.reconnectWebSocket) {
            initWebSocket();
        }
    };

    const webSocketOnError = function(event) {
        logger.error("WebSocketManager Error, error_event: ", event);
        refreshWebSocketConnection();
    };

    const webSocketOnMessage = function(event) {
        logger.debug("Message received from webSocket server", event.data);
        const response = JSON.parse(event.data);
        switch (response.topic) {
            case ROUTE_KEY.SUBSCRIBE:
                if (response.content.status === "success") {
                    response.content.topics.forEach((function (topicName) {
                        topicSubscription.subscribed.add(topicName);
                        topicSubscription.pending.delete(topicName);
                    }));
                    invokeCallbacks(callbacks.subscriptionUpdate, response);
                } else {
                    invokeCallbacks(callbacks.subscriptionFailure, response);
                }
                break;
            case ROUTE_KEY.HEARTBEAT:
                logger.debug("Heartbeat response received");
                heartbeatConfig.pendingResponse = false;
                break;
            default:
                if (response.topic) {
                    if (callbacks.allMessage.size === 0 && callbacks.topic.size === 0) {
                        logger.warn('No registered callback listener for Topic: ', response);
                        return;
                    }
                    invokeCallbacks(callbacks.allMessage, response);
                    if (callbacks.topic.has(response.topic)) {
                        invokeCallbacks(callbacks.topic.get(response.topic), response);
                    }
                } else if (response.message) {
                    logger.warn("WebSocketManager Message Error, error: ", response);
                } else {
                    logger.warn("Invalid incoming message, error: ", response);
                }
        }
    };

    const closeWebSocket = function(reason) {
        if (webSocket && webSocket.readyState !== WebSocket.CLOSED) {
            webSocket.close(1000, reason);
            return true;
        }
        return false;
    };

    const refreshWebSocketConnection = function () {
        if (!online) {
            closeWebSocket("Network Offline, Closing WebSocket Manager");
            return;
        }
        clearTimeout(reconnectConfig.lifeTimeTimeoutHandle);
        clearInterval(heartbeatConfig.intervalHandle);

        if (reconnectConfig.linearConnectAttempt < MAX_LINEAR_CONNECT_ATTEMPTS) {
            reconnectConfig.linearConnectAttempt++;
            logger.debug("Starting Consecutive WebSocket reconnect, Attempt : " + reconnectConfig.linearConnectAttempt);
            reconnectConfig.reconnectWebSocket = true;
            getWebSocketConnConfig();
        } else if (reconnectConfig.exponentialConnectAttempt < MAX_EXPONENTIAL_CONNECT_ATTEMPTS) {
            reconnectConfig.exponentialConnectAttempt++;
            reconnectConfig.exponentialBackOffTime *= 2;
            logger.debug("Starting Exponential WebSocket reconnect, Attempt : "
                + reconnectConfig.exponentialConnectAttempt + " with delay "
                + reconnectConfig.exponentialBackOffTime + " sec.");

            // required for scenarios when error and close events are fired back to back
            webSocketConfig.promiseCompleted = false;
            webSocketConfig.connConfig = null;

            reconnectConfig.exponentialTimeoutHandle = setTimeout(function() {
                reconnectConfig.reconnectWebSocket = true;
                getWebSocketConnConfig();
            }, 1000 * reconnectConfig.exponentialBackOffTime);
        } else if (webSocketConfig.promiseCompleted) {
            logger.error("Could not connect to WebSocket after several attempts");
            terminateWebSocketManager();
        }
    };

    const terminateWebSocketManager = function () {
        resetState();
        closeWebSocket("Terminating WebSocket Manager");
        logger.error("WebSocket Initialization failed");
        reconnectConfig.websocketInitFailed = true;
        clearInterval(networkConnectivityChecker);
        invokeCallbacks(callbacks.initFailure);
    };

    const createWebSocketPayload = function (key, content) {
        return JSON.stringify({
            "topic": key,
            "content": content
        });
    };

    const sendMessage = function(payload) {
        Utils.assertIsObject(payload, "payload");
        if (payload.topic === undefined || invalidSendMessageRouteKeys.has(payload.topic)) {
            logger.warn("Cannot send message, Invalid topic", payload);
            return;
        }
        try {
            payload = JSON.stringify(payload);
        } catch (error) {
            logger.warn("Error stringify message", payload);
            return;
        }
        if (webSocket && webSocket.readyState === WebSocket.OPEN) {
            logger.debug('WebSocketManager sending message', payload);
            webSocket.send(payload);
        } else {
            logger.warn("Cannot send message, web socket connection is not open");
        }
    };

    const subscribeTopics = function(topics) {
        Utils.assertNotNull(topics, 'topics');
        Utils.assertIsList(topics);

        topics.forEach(function (topic) {
            topicSubscription.pending.add(topic);
        });

        if (webSocket && webSocket.readyState === WebSocket.OPEN) {
            webSocket.send(createWebSocketPayload(ROUTE_KEY.SUBSCRIBE, {"topics": topics}));
        }
    };

    const validWebSocketConnConfig = function (connConfig) {
        if (Utils.isObject(connConfig) && Utils.isObject(connConfig.webSocketTransport)
            && Utils.isString(connConfig.webSocketTransport.url)
            && Utils.validWSUrl(connConfig.webSocketTransport.url)
            && Utils.isNumber(connConfig.webSocketTransport.transportLifeTimeInSeconds) &&
            connConfig.webSocketTransport.transportLifeTimeInSeconds >= MIN_WEBSOCKET_LIFETIME) {
            return true;
        }
        logger.error("Invalid WebSocket Connection Configuration", connConfig);
        return false;
    };

    const getWebSocketConnConfig = function () {
        if (reconnectConfig.websocketInitFailed) {
            return;
        }
        webSocketConfig.connConfig = null;
        webSocketConfig.promiseCompleted = false;
        webSocketConfig.promiseHandle = callbacks.getWebSocketTransport();
        webSocketConfig.promiseHandle
            .then(function(response) {
                    webSocketConfig.promiseCompleted = true;
                    logger.debug("Successfully fetched webSocket connection configuration");
                    if (!validWebSocketConnConfig(response)) {
                        terminateWebSocketManager();
                        return;
                    }
                    webSocketConfig.connConfig = response;
                    if (!online) {
                        return;
                    }
                    if (closeWebSocket("Restarting WebSocket Manager")) {
                        return;
                    }
                    initWebSocket();
                },
                function(reason) {
                    webSocketConfig.promiseCompleted = true;
                    logger.error("Failed to fetch webSocket connection configuration", reason);
                    refreshWebSocketConnection();
                });
    };

    const initWebSocket = function() {
        if (reconnectConfig.websocketInitFailed) {
            return;
        }
        logger.debug("Initializing Websocket Manager");
        try {
            if (validWebSocketConnConfig(webSocketConfig.connConfig)) {

                webSocket = new WebSocket(webSocketConfig.connConfig.webSocketTransport.url);
                webSocket.addEventListener("open", webSocketOnOpen);
                webSocket.addEventListener("message", webSocketOnMessage);
                webSocket.addEventListener("error", webSocketOnError);
                webSocket.addEventListener("close", webSocketOnClose);
            } else {
                if (webSocketConfig.promiseCompleted) {
                    terminateWebSocketManager();
                }
            }
        } catch (error) {
            logger.error("Error Initializing web-socket-manager", error);
            terminateWebSocketManager();
        }
    };

    const onConnectionGain = function(cb) {
        Utils.assertTrue(Utils.isFunction(cb), 'cb must be a function');
        callbacks.connectionGain.add(cb);
        if (webSocket && webSocket.readyState === WebSocket.OPEN) {
            cb();
        }
        return () => callbacks.connectionGain.delete(cb);
    };

    const onConnectionLost = function(cb) {
        Utils.assertTrue(Utils.isFunction(cb), 'cb must be a function');
        callbacks.connectionLost.add(cb);
        if (webSocket && webSocket.readyState === WebSocket.CLOSED) {
            cb();
        }
        return () => callbacks.connectionLost.delete(cb);
    };

    const onInitFailure = function(cb) {
        Utils.assertTrue(Utils.isFunction(cb), 'cb must be a function');
        callbacks.initFailure.add(cb);
        if (reconnectConfig.websocketInitFailed) {
            cb();
        }
        return () => callbacks.initFailure.delete(cb);
    };

    const init = function(transportHandle) {
        Utils.assertTrue(Utils.isFunction(transportHandle), 'transportHandle must be a function');
        if (callbacks.getWebSocketTransport !== null) {
            logger.warn("Web Socket Manager was already initialized");
            return;
        }
        callbacks.getWebSocketTransport = transportHandle;

        getWebSocketConnConfig();
    };

    const onSubscriptionUpdate = function(cb) {
        Utils.assertTrue(Utils.isFunction(cb), 'cb must be a function');
        callbacks.subscriptionUpdate.add(cb);
        return () => callbacks.subscriptionUpdate.delete(cb);
    };

    const onSubscriptionFailure = function(cb) {
        Utils.assertTrue(Utils.isFunction(cb), 'cb must be a function');
        callbacks.subscriptionFailure.add(cb);
        return () => callbacks.subscriptionFailure.delete(cb);
    };

    const onMessage = function(topicName, cb) {
        Utils.assertNotNull(topicName, 'topicName');
        Utils.assertTrue(Utils.isFunction(cb), 'cb must be a function');
        if (callbacks.topic.has(topicName)) {
            callbacks.topic.get(topicName).add(cb);
        } else {
            callbacks.topic.set(topicName, new Set([cb]));
        }
        return () => callbacks.topic.get(topicName).delete(cb);
    };

    const onAllMessage = function (cb) {
        Utils.assertTrue(Utils.isFunction(cb), 'cb must be a function');
        callbacks.allMessage.add(cb);
        return () => callbacks.allMessage.delete(cb);
    };

    this.init = init;
    this.onInitFailure = onInitFailure;
    this.onConnectionGain = onConnectionGain;
    this.onConnectionLost = onConnectionLost;
    this.onSubscriptionUpdate = onSubscriptionUpdate;
    this.onSubscriptionFailure = onSubscriptionFailure;
    this.onMessage = onMessage;
    this.onAllMessage = onAllMessage;
    this.subscribeTopics = subscribeTopics;
    this.sendMessage = sendMessage;

    this.closeWebSocket = function() {
        resetState();
        clearInterval(networkConnectivityChecker);
        closeWebSocket("User request to close WebSocket");
    };
};

const WebSocketManagerConstructor = () => {
    return new WebSocketManager();
};

const setGlobalConfig = config => {
    const loggerConfig = config.loggerConfig;
    LogManager.updateLoggerConfig(loggerConfig);
};

const WebSocketManagerObject = {
    create: WebSocketManagerConstructor,
    setGlobalConfig: setGlobalConfig,
    LogLevel: LogLevel,
    Logger: Logger
};

export { WebSocketManagerObject };