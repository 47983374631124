
export const LOGS_DESTINATION = {
  NULL: "NULL",
  CLIENT_LOGGER: "CLIENT_LOGGER",
  DEBUG: "DEBUG"
};

export const MIN_WEBSOCKET_LIFETIME = 3600;
export const MAX_LINEAR_CONNECT_ATTEMPTS = 3;
export const MAX_EXPONENTIAL_CONNECT_ATTEMPTS = 5;
export const HEARTBEAT_INTERVAL = 10; //seconds

export const ROUTE_KEY = {
  SUBSCRIBE: "aws/subscribe",
  UNSUBSCRIBE: "aws/unsubscribe",
  HEARTBEAT: "aws/heartbeat"
};
